import React                  from "react";
import {Select, Tag, Tooltip, Icon} from "antd";
import joursFeries            from "@socialgouv/jours-feries";
import moment                 from 'moment';


const Option = Select.Option;

function convertirLettreEnNombre(lettre) {
    return lettre.charCodeAt(0) - 64; // A=1, B=2, ..., Z=26
}

function convertirPartieOperateur(partieOperateur) {
    return partieOperateur.split('').map(convertirLettreEnNombre).join('');
}




export function getEtiquetteTypeSAV (pe_famille) {

  switch  (pe_famille) {
    case 'Reparation' :
    return "Réparation"
    case 'Téléphone' :
    return "Industry";
    case 'IsAccessoire' :
    return "Accessoire";
  }
    
    return "Autre";

}

export function getEtiquetteColorTypeSAV (pe_famille) {

  switch  (pe_famille) {
   case 'Reparation' :
    return "purple"
   case 'Téléphone' :
   return "volcano";
   case 'IsAccessoire' :
   return "lemon";
  }
   
   return "grey";
 
}


export function getEtiquetteNbJourSAV (pe_nombreJour) {

  if(pe_nombreJour > 60){
    return 'blue'
  }

  if(pe_nombreJour > 30){
    return 'lime'
  }

  if(pe_nombreJour > 5){
    return 'volcano'
  }
 
  return 'volcano'

}



  export function getHistoryTestEffectue(pe_history){

  //  console.log(pe_history)

    let qteTest = 0;

    if (pe_history.length == 0){
      return 0
    }

    for (var i = 0; i < pe_history.length; i++) {
      if(pe_history[i].length > 0 ) {
        qteTest ++;
      }
    }

    return qteTest;

  }



export function generateRandomNumberRUM (min, max) {

  let date = new Date();

  let d = date.getDate();
  let m = date.getMonth() + 1;
  let y = date.getFullYear();

  let dateString = (d <= 9 ? '0' + d : d)  + (m <= 9 ? '0' + m : m)  + y;
   return dateString + '-' + Math.floor(Math.random()*(max-min+1)+min)
}



export function isValidIBAN(iban) {
  // Supprime les espaces et les majuscules
  const cleanedIban = iban.replace(/\s+/g, '').toUpperCase();
  
  // Vérifie si le format de l'IBAN est valide (longueur minimale et caractères alphanumériques)
  const ibanRegex = /^[A-Z]{2}[0-9]{2}[A-Z0-9]{1,30}$/;
  if (!ibanRegex.test(cleanedIban)) {
      return false;
  }

  // Remettre les deux premières lettres à la fin et remplacer les lettres par des nombres (A=10, B=11, etc.)
  const rearrangedIban = cleanedIban.slice(4) + cleanedIban.slice(0, 4);
  const numericIban = rearrangedIban.replace(/[A-Z]/g, (match) => match.charCodeAt(0) - 55);

  // Utiliser l'algorithme de mod 97 pour vérifier l'IBAN
  let remainder = '';
  for (const digit of numericIban) {
      remainder = (remainder + digit) % 97;
  }

  return remainder === 1;
}


// Fonction pour valider un BIC
export function isValidBIC(bic) {
  // Supprime les espaces et majuscules
  const cleanedBic = bic.replace(/\s+/g, '').toUpperCase();

  // Vérifie si le format du BIC est valide (8 ou 11 caractères, lettres majuscules et chiffres)
  const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
  return bicRegex.test(cleanedBic);
}





export function validerRIO(rio) {

    if(rio == ''){
      return false;
    }

    if(rio == undefined){
      return false;
    }

    if(rio == null){
      return false;
    }

    if (rio.length !== 12) {
       return false; // Le RIO doit avoir exactement 12 caractères
   }

   console.log(rio)
   // Extraire les parties du RIO
   let partieOperateur    = rio.slice(0, 2);
   let partieContrat      = rio.slice(2, 10);
   let partieCle          = rio.slice(10, 12);

   // Convertir les lettres de la partie opérateur en nombres
   let operateurConverti = convertirPartieOperateur(partieOperateur);

   // Construire la chaîne complète des nombres
   let numerosComplets = operateurConverti + partieContrat + "00";

   // Calculer le reste de la division par 97
   let reste = parseInt(numerosComplets) % 97;

   // Calculer la clé de contrôle
   let cleCalculee = 97 - reste;
   if (cleCalculee === 0) {
       cleCalculee = 97; // Correction si le reste est 0, la clé doit être 97
   }

   console.log(cleCalculee, partieCle)
   if(cleCalculee == partieCle){
     return true;
   }else{
     return false;
   }

   // Vérifier si la clé de contrôle correspond
//   console.log(cleCalculee, partieCle )
  // return cleCalculee === partieCle;
}


export function dateToJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }


export function disabledDatePortabilite(current) {

  var result            = new Date();
  var result2Month      = new Date();

  result.setDate(result.getDate() + 3);
  result2Month.setDate(result2Month.getDate() + 60);

  if(moment(current).day() === 0){
    return true;
  }

  if(current && current.valueOf() < result ){
    return true;
  }

  if(current && current.valueOf() > result2Month ){
    return true;
  }


  // Récupération des jours fériés
  let jourFerie = joursFeries(moment(current).year());

  if( getFrDateFormat(jourFerie["Jour de l'an"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Fête du travail"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Victoire des alliés"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Fête Nationale"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Assomption"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Toussaint"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Armistice"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Noël"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Lundi de Pâques"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Ascension"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Lundi de Pentecôte"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Vendredi Saint"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  if( getFrDateFormat(jourFerie["Saint Étienne"])  ==  getFrDateFormat(current) ) {
    return true;
  }

  return false;

}


export function renderLigneMobileData(dataMO) {

  return (dataMO/1000).toFixed(0) + 'G'
}


export function renderLigneMobileEngagement(pe_IsSansAbonnement, NombreMois) {

  return pe_IsSansAbonnement == 1 ? "Sans eng." : NombreMois + " mois";

}


export function DataURIToBlob(dataURI) {

       const splitDataURI = dataURI.split(',')
       const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
       const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

       const ia = new Uint8Array(byteString.length)
       for (let i = 0; i < byteString.length; i++)
           ia[i] = byteString.charCodeAt(i)

       return new Blob([ia], { type: mimeString })
 }


export function renderSwitchEtatLigneMobileTELECOM(param) {
     switch(param) {
       case 'Active':
         return '#87d068';
       case 'Suspendue':
         return 'orange';
        case 'Résiliée':
           return '#f50';
       default:
         return 'geekblue';
     }
}




export function renderQuantiteStockBoutique(param) {
 
  if(param <= 0){
    return 'red';
  }
  if(param <= 1){
    return 'orange';
  }

  if(param <= 5){
    return 'blue';
  }

  return 'green';

}

export function renderRotationStockBoutique(param, pe_NBVente) {
 
  
  if(pe_NBVente == 0){
    return 'red';
  }

  if(param >= 90){
    return 'red';
  }
  if(param >= 45){
    return 'orange';
  }

  if(param >= 5){
    return 'blue';
  }

  return 'green';

}






export function renderSwitchEtatLigne(param) {
     switch(param) {
       case 'Active':
         return 'green';
       case 'Suspendue':
         return 'orange';
       default:
         return 'geekblue';
     }
}



export function renderSwitchBoolEtatLignePhoner(param, pe_dateFinContrat, pe_IsSansAbonnement) {

     if(param == undefined){
        return 'OUI';
     }else{
       if(pe_IsSansAbonnement == false && pe_dateFinContrat.getFullYear() == 1970){
         return 'OUI';
       }else{
         return 'NON';
       }
     }
   }


export function RenderformatDataPourc(pe_dataConso, pe_dataForfait){

     if(pe_dataForfait == 0){
       return '';
     }
     return ((pe_dataConso* 100) / pe_dataForfait).toFixed(0)
   }


export function formatNumberMobile(pe_num) {

  let number = '';

  if(pe_num != '' && pe_num != undefined){
    pe_num = pe_num.replace(/ /g, '')
  }

  number = pe_num;

  // Ajouter le masque qui servira a la partie télécom B2C

  return number;
}


export function renderDureeAbonnement(param) {

   if(param == 0){
      return 'sans eng.';
   }else{
      return param + ' mois';
   }
 }


export function renderSwitchEtatLignePhoner(param, pe_dateFinContrat, pe_IsSansAbonnement) {

    if(param == undefined){
       return 'red';
    }else{

      if(pe_IsSansAbonnement == false && pe_dateFinContrat.getFullYear() == 1970){
        return 'red';
      }else{
        return 'green';
      }
    }
  }


export function formatAppel(pe_seconde){
    return (pe_seconde / 60).toFixed(2) + ' min';
}




export  function renderGradeComposant(grade) {
  if(grade == "")
    return "";
  
  return " (Grade : "+grade+")";
}



export function renderSwitchEtatComposant(param) {
  switch(param) {
    case 'Vendu': return 'orange';
    case 'Disponible': return 'green';
    case 'En location': return 'geekblue';
    case 'Transit Navette': return 'lime';
    case 'Réformé': return 'red';
    case 'En inventaire': return 'cyan';
    default: return '';
  }
}


export function renderColorCaract(caract) {

  if(caract.ValeurAlpha != "" || caract.ValeurListe != "" || caract.ValeurNum != ""){
    if(caract.MessageErreur != undefined){
      return "red";
    }else{
      return "green";
    }

   
  }else{
   
      return "orange";
    
  }

}


export function  generateRandomNumber (min, max) {
    return Math.floor(Math.random()*(max-min+1)+min)
  }


export function  calculTTC_VIA_HT (pe_IsTVA_SurMarge, pe_ht, pe_tva) {

    let prixTTC = 0;

    if(pe_IsTVA_SurMarge){
        prixTTC = Number(pe_ht);
    }else{
      pe_ht = Number(pe_ht);
      prixTTC  = pe_ht +  (pe_ht / 100 *  Number(pe_tva));
      prixTTC  = prixTTC.toFixed(2);
    }

    return prixTTC;

  }




/*
  Retourne le contenu d'un champ de type Array d'un objet (obj[array])
  Si l'objet ou le champ est null, undefined ou '', alors retourne []
*/
export function getObjectArrayContent(obj, array) {
  if(isObjectNull(obj) || isObjectNull(obj[array]))
    return [];
  return cleanJSONContent(obj[array]);
}

/*
  Retourne 'true' si une variable est null, undefined ou '', et 'false' sinon
*/
export function isObjectNull(item) {
  if(item === undefined || item === null || item === '')
    return true;
  return false;
}

/*
  Retourne la valeur de l'objet s'il est non null, undefined ou '' et 'valueIfNull' sinon
*/
export function getValueIfNull(object, valueIfNull) {
  return (isObjectNull(object) ? valueIfNull : object);
}

export function truncateString(str, num = 40) {
  return str.length <= num ? str : str.slice(0, num) + '...';
}

export function isJourFerieOrSunday(day) {
  if(day.day() === 0) //dimanche
    return true;
  day = day.startOf("day").utc().toDate();

  var d = new Date(day);
  let joursFeriesList = joursFeries(d.getFullYear());

  for(let jourFerie in joursFeriesList) {
    if(day.getTime() === joursFeriesList[jourFerie].getTime())
      return true;
  }
}

export function getMarge(pe_PrixHT, pe_IsTvaMarge, pe_Derpa, pe_DerpaTVASurMarge) {
  //console.log('getPourcentageMarge->' + pe_PrixHT, pe_IsTvaMarge, pe_Derpa, pe_DerpaTVASurMarge);
  if(pe_IsTvaMarge == 1)
    return (pe_PrixHT - pe_DerpaTVASurMarge).toFixed(2);
  return (pe_PrixHT - pe_Derpa).toFixed(2);
}

export function getPourcentageMarge(pe_PrixHT, pe_IsTvaMarge, pe_Derpa, pe_DerpaTVASurMarge) {
  // Marge brut HT  / PVHT
  if(pe_IsTvaMarge == 1)
    return (((pe_PrixHT - pe_DerpaTVASurMarge) / pe_PrixHT) * 100).toFixed(2);
  return (((pe_PrixHT - pe_Derpa) / pe_PrixHT) * 100).toFixed(2);
}

export function getBoolListToOptions() {
  var list = [];
  list.push(<Option key={1} value={1}>OUI</Option>);
  list.push(<Option key={0} value={0}>NON</Option>);
  return list;
}

export function getBoolListToOptionsFilter() {
  var list = [];
  list.push(<Option key={-1} value={-1}>Tous</Option>);
  list.push(<Option key={1} value={1}>OUI</Option>);
  list.push(<Option key={0} value={0}>NON</Option>);
  return list;
}

export function getListToOptions(list, idItem, libelleItem) {

  //console.log(list)
  //console.log(idItem)
  //console.log(libelleItem)
  var listToOptions = [];
  for (let i=0; i<list.length; i++) {
    let value = parseInt(list[i][idItem]);
    if(isNaN(value))
      value = list[i][idItem];
    let libelle = list[i][libelleItem];
    listToOptions.push(<Option value={value} key={value}>{libelle}</Option>);
  }
  return listToOptions;
}

export function getLibelleFromOptionsList(list, id) {
  for(let item in list) {
    if(list[item].props.value === id)
      return list[item].props.children;
  }
  return '';
}

export function addAllValueToOptions(list) {
  return [(<Option value={0} key={0}>{"Tous"}</Option>)].concat(list);
}

export function addAucunValueToOptions(list) {
  return [(<Option value={0} key={0}>{"Aucun"}</Option>)].concat(list);
}

export function addChoiceInfoToOptions(list, libelle) {
  return [(<Option value={0} key={0}>{libelle}</Option>)].concat(list);
}

export function convertToStatusList(list, id, value) {

  if(list == undefined){
    list = [];
  }

  const convertedList = list.map(item => {
    return {key: item[id], value: item[value]};
  });

  return convertedList;
}

export function concatNomPrenom(nom, prenom) {
  return nom + ' ' + prenom;
}

export function concatNomPrenomSociete(nom, prenom, societe) {

  if(societe != ''){
      return societe;
  }else{
      return nom + ' ' + prenom;
  }



}

export function getFrDateFormat(date) {
  if(date === undefined || date === null)
    return null;
  return formatDate(new Date(date),'dd/MM/yyyy');
}

export function getFrDateFormatHeure(date) {
  if(date === undefined || date === null)
    return null;
  return formatDate(new Date(date),'dd/MM/yyyy hh:mm:ss');
}

export function formatDate(x, y) {
  if(x.getTime() === 0)
    return null;
  if(x.getFullYear() === 1970)
    return '-'

  var z = {
      M: x.getMonth() + 1,
      d: x.getDate(),
      h: x.getHours(),
      m: x.getMinutes(),
      s: x.getSeconds()
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function(v) {
      return ((v.length > 1 ? "0" : "") + eval('z.' + v.slice(-1))).slice(-2)
  });

  return y.replace(/(y+)/g, function(v) {
      return x.getFullYear().toString().slice(-v.length)
  });
}

export function formatCurrency(euros) {

  if(euros == undefined){
    return '';
  }else{
    return ((euros*100) / 100).toFixed(2).toLocaleString() + "€"
  }

}

export function formatCurrencyWithPlus(euros) {

  if(euros == undefined){
    return '';
  }else{
    let value = ((euros*100) / 100).toFixed(2).toLocaleString();
     if(value >= 0) {
        return '+'+ ((euros*100) / 100).toFixed(2).toLocaleString() + "€"
     }else{
       return ((euros*100) / 100).toFixed(2).toLocaleString() + "€"
     }

  }

}

export function cleanJSONContent(data) {
  if(data === null || data === "" || data === undefined) {
    return [];
  }

  return JSON.parse(JSON.stringify(eval("(" + data + ")")))
}


export function parseJSONItem(list, itemName) {
  for(var i in list) {
    list[i][itemName] = cleanJSONContent(list[i][itemName]);
  }
  return list;
}

export function getSwitchEtat(status) {
  switch(status) {
    case 1: return 'geekblue';
    case 2: return 'orange';
    case 3: return 'green';
    case 4: return 'purple';
    default: return 'green';
  }
}



export function getSwitchEtatPennylane(status) {

  switch(status) {

    case 'A importer': 
      return 'orange';

    case 'A traiter': 
      return 'lime';

    case 'Encaissée': 
      return 'green';

    case 'A venir': 
      return 'geekblue';
    
    case 'En retard': 
      return 'volcano';  

    default: return 'green';

  }
}





export function getColorMarge(pe_marge) {

  if(pe_marge > 60){
    return 'green';
  }

  if(pe_marge > 50){
    return 'geekblue';
  }
  if(pe_marge > 15){
    return 'orange';
  }

  return 'red';

}

export function getColorResultatFinancier(pe_val) {

  if(pe_val >= 0){
    return 'green';
  }else{
    return 'red';
  }

}

export function getColorStock(pe_qte, pe_stockMini, pe_stockMaxi) {

  if(pe_qte > pe_stockMaxi){
    return 'geekblue';
  }

  if(pe_qte < pe_stockMini){
    return 'red';
  }
  if(pe_qte == pe_stockMini){
    return 'orange';
  }

  if(pe_qte > pe_stockMini){
    return 'green';
  }

  return '';

}


export function getColorStockDemo(pe_qte) {

  if(pe_qte > 0){
    return 'green';
  }else{
    return 'red'
  }

}


export function getMoisWeekToNumero(month, week) {
  let value = '';


  switch (month) {
    case 1:
      value = 'Jan';
      break;
    case 2:
      value = 'Fév'
        break;
    case 3:
      value = 'Mars'
        break;
    case 4:
      value = 'Avr'
        break;
    case 5:
      value = 'Mai'
        break;
    case 6:
      value = 'Juin'
        break;
    case 7:
      value = 'Juil'
        break;
    case 8:
      value = 'Aout'
        break;
    case 9:
      value = 'Sept'
        break;
    case 10:
      value = 'Oct'
        break;
    case 11:
      value = 'Nov'
        break;
    case 12:
      value = 'Déc'
        break;
    default:

  }

  return value + ' n°' +week ;

}


export function getMoisToNumero(pe_Numero) {
  let value = '';


  switch (pe_Numero) {
    case 1:
      value = 'Janvier';
      break;
    case 2:
      value = 'Février'
        break;
    case 3:
      value = 'Mars'
        break;
    case 4:
      value = 'Avril'
        break;
    case 5:
      value = 'Mai'
        break;
    case 6:
      value = 'Juin'
        break;
    case 7:
      value = 'Juillet'
        break;
    case 8:
      value = 'Aout'
        break;
    case 9:
      value = 'Septembre'
        break;
    case 10:
      value = 'Octobre'
        break;
    case 11:
      value = 'Novembre'
        break;
    case 12:
      value = 'Décembre'
        break;
    default:

  }

  return value;

}


export function getColorChargeFixes(idCharge) {
  switch(idCharge) {
    case 1:
      return 'green';
    case 2:
      return 'red';
    case 3:
      return 'orange';
    case 4:
      return 'geekblue';
    case 5:
      return 'purple';
    case 6:
      return 'grey';
    case 7:
      return 'indigo';
    case 7:
      return 'black';
    default:
      return 'green';
  }
}



export function getColorTypeCompteBancaire(pe_const) {
  switch(pe_const) {
    case 'checking':
      return 'green';
    case 'card':
      return 'magenta';
    default:
      return '';
  }
}


export function getColorSoldeCompteBancaire(pe_val) {
  if(pe_val >= 0){
    return 'green';
  }else{
    return 'volcano';
  }
}


export function getColorSoldeCompteBancaireDashboard(pe_val) {
  console.log(pe_val);

  if(pe_val >= 0){
    return 'white';
  }else{
    return 'red';
  }
}


export function getRCPFormatEuro(rcp) {


  return (
    <Tag color={'yellow'}>{rcp + '€'}</Tag>
  );
}


export function getBoolToIcon(param) {
  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ?  <Icon style = {{fontSize : 20, color: 'green'}} theme="twoTone" twoToneColor="#52c41a"  type="check-circle" /> :  <Icon style = {{fontSize : 20, color: 'orange'}} type="warning" />)
//  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ? 'green' : 'blue');
}


export function getBoolToString(param) {
  return ((param === 1 || param === '1' || param === true) ? 'OUI' : 'NON');
}


export function getBoolToColor(param) {
  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ? 'green' : 'blue');
}


export function getBoolToColorWarning(param) {
  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ? 'green' : 'orange');
}


export function getBoolToColorRemise(param) {
  return ((param === 1 || param === '1' || param === 'OUI' || param === true) ? 'orange' : 'green');
}


export function getFormatedTVA(isTVASurMarge) {
  isTVASurMarge = parseInt(isTVASurMarge);
  let color = (isTVASurMarge === 1 ? 'geekblue' : 'green');
  let content = (isTVASurMarge === 1 ? 'Sur marge' : 'Standard');
  return (
    <Tag color={color}>{content}</Tag>
  );
}


export function getFormatedPriceWithTVA(isTVASurMarge, montant, placement='topLeft') {
  isTVASurMarge = parseInt(isTVASurMarge);
  return (
    <Tooltip placement={placement} title={isTVASurMarge === 0 ? "TVA standard" : "TVA sur marge"}>
      <Tag color={isTVASurMarge === 0 ? 'green' : 'blue'}>{formatCurrency(montant)}</Tag>
    </Tooltip>
  )
}

export function getFormatedPriceWithTVARemise(isTVASurMarge, montant, remise, placement='topLeft') {
  isTVASurMarge = parseInt(isTVASurMarge);
  return (
    <Tooltip placement={placement} title={"Remise : " + remise.toFixed(2) + '€ TTC | ' + (isTVASurMarge === 0 ? "TVA standard" : "TVA sur marge")}>
      <Tag color={isTVASurMarge === 0 ? 'green' : 'blue'}>{formatCurrency(montant)}</Tag>
    </Tooltip>
  )
}

export function getFormatedPriceWithTVAV2(isTVASurMarge, montant, montantHT, placement='topLeft') {
  isTVASurMarge = parseInt(isTVASurMarge);
  return (
    <Tooltip placement={placement} title={montantHT + '€ HT | '  + (isTVASurMarge === 0 ? "TVA standard" : "TVA sur marge")}>
      <Tag color={isTVASurMarge === 0 ? 'green' : 'blue'}>{formatCurrency(montant)}</Tag>
    </Tooltip>
  )
}


