import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  USER_DASHBOARD_DATA,
  USER_REGISTERING_SET,
  USER_INTERVENTION_DATA_CLEAN,
  USER_INTERVENTION_DATA,
  USER_FACTURE_DATA,
  USER_FACTURE_DATA_CLEAN,
  USER_MODELE_DATA_CLEAN,
  USER_MODELE_DATA,
  USER_AVOIR_DATA_CLEAN,
  USER_AVOIR_DATA,
  USER_REMISE_DATA_CLEAN,
  USER_REMISE_DATA,
  USER_TELEPHONE_LIST_DATA,
  USER_PANIER_ACHAT_DATA,
  USER_PANIER_ACHAT_DATA_CLEAN,
  USER_FLOTTE_DATA,
  USER_FLOTTE_DATA_CLEAN,
  USER_SIM_DATA,
  USER_SIM_DATA_CLEAN,
  USER_GENERATE_PARAM_LIST,
  USER_ITEM_CART,
  USER_ITEM_CART_CLEAN
} from "../../constants/ActionTypes";
import axios from 'util/Api'
import React from "react";
import {Select, message} from "antd";
const Option = Select.Option;

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
//*****************************************************************
//-------------- ------ -- REGISTERING PART  -------------- ------ --
//*****************************************************************
export const checkGUID = async (GUID) => {

  return new Promise((resolve,reject)=>{
    axios.post('auth/check', {GUID: GUID}
    ).then(({data}) => {

      if(data.success)
        resolve(data)
      else {
        window.location.replace("/")
        console.log("payload: data.error", data.error);
        reject(data.error)
      }
    }).catch(function (error) {reject(error)});
  })
};

export const checkGUID2 = (GUID) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/check', {GUID: GUID}
    ).then(({data}) => {

      if(data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_REGISTERING_SET, payload: data.compte});
      } else {
        window.location.replace("/")
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignUp = ({GUID, Password, Password_Confirm}) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/register', {
      GUID:GUID,
      Password: Password,
      Password_Confirm:Password_Confirm
    }).then(({data}) => {

      if (data.success) {
        //localStorage.setItem("token", JSON.stringify(data.token.access_token));
        //localStorage.setItem("token", JSON.stringify(data.token));
        //axios.defaults.headers.common['secret-token'] = "Bearer " + data.token;

        console.log(data)
        dispatch({type: FETCH_SUCCESS, payload: data.success});
        dispatch({type: USER_REGISTERING_SET, payload: data.success});
        window.location.replace("/")
        //dispatch({type: USER_TOKEN_SET, payload: data.token.access_token});
        //dispatch({type: USER_DATA, payload: data.user});
      } else {
        console.log("payload: data.error", data.error);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

//*****************************************************************
//-------------- ------ -- LOGIN / LOGOUT PART  -------------- ------ --
//*****************************************************************

export const userSignIn = ({email, password}) => {
  return (dispatch) => {

    dispatch({type: FETCH_START});
    axios.post('auth/login', {
        username: email,
        password: password,
      }
    ).then(({data}) => {
     console.log("userSignIn: ", data);

    // CREDENTIAL_ERROR

      if (data.success) {
        localStorage.setItem("token", JSON.stringify(data.token));
        axios.defaults.headers.common['secret-token'] = "Bearer " + data.token;
        dispatch({type: FETCH_SUCCESS, payload: data.success});
        dispatch({type: USER_TOKEN_SET, payload: data.token});
      } else {
        console.log('ERRRRRRR ' + data)
        dispatch({type: FETCH_ERROR, payload: data.errors});
      }
    }).catch(function (error,data) {

      var rep = error.response;
      var err = '';

      if(rep === undefined){
        err = 'NETWORK_ERROR';
      }else{
        err = rep.data.errors;
        if(err === undefined || err === '' || JSON.stringify(err) === '{}'){
          err = 'CREDENTIAL_ERROR'
        }
      }
      dispatch({type: FETCH_ERROR, payload: err});
    });
  }
};

export const userSignOut = () => {

  return (dispatch) => {
    dispatch({type: FETCH_START});

    localStorage.removeItem("token");
    axios.post('auth/logout').then(({data}) => {

      if (data.success) {
        setTimeout(() => {
          localStorage.removeItem("token");
          dispatch({type: FETCH_SUCCESS , payload: data.success});
          dispatch({type: SIGNOUT_USER_SUCCESS});
        }, 500);
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    })
  }
};

export const resetPassword = ({Email}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/reset-password',{
        Email: Email,
    }).then(({data}) => {
  //    console.log("resetPassword: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const newPassword = ({Password, Password_confirm, GUID}) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post('auth/reset-password/confirm',{
        Password: Password,
        Password_confirm: Password_confirm,
        GUID: GUID,
    }).then(({data}) => {
    //  console.log("newPassword: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
        window.location.replace("/")
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

//*****************************************************************
//-------------- ------ --  USER PART  -------------- ------ --
//*****************************************************************
export const getUser = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('profile/me',
    ).then(({data}) => {
    //  console.log("userSignIn: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateUser = ({Nom,Prenom,Portable,Fax,Telephone,IsNewletter,IsReceptionOffreSpeciales}) => {
  return (dispatch) => {
  //  console.log('updateUser  -> ' + IsNewletter + '  ' + IsReceptionOffreSpeciales);

    dispatch({type: FETCH_START});

    axios.post('profile/me', {
        Nom:Nom,
        Prenom:Prenom,
        Portable:Portable,
        Fax:Fax,
        Telephone:Telephone,
        IsNewletter : IsNewletter ? 1 : 0,
        IsReceptionOffreSpeciales : IsReceptionOffreSpeciales ? 1 : 0,
      }
    ).then(({data}) => {
    //  console.log("userUpdated: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
        window.location.replace("/moncompte")
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


//*****************************************************************
//-------------- ------ --  FACTURES PART  -------------- ------ --
//*****************************************************************

export const getAvoir = () => {

  return (dispatch) => {
      dispatch({
        type: USER_AVOIR_DATA_CLEAN,
        payload: [],
      });

    dispatch({type: FETCH_START});

    axios.get('profile/avoir',
    ).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_AVOIR_DATA,
          payload: data.avoir_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const generateFacture = (codeFacture, pe_IsEmailFacture) => {



  return (dispatch) => {

    dispatch({type: FETCH_START});

    axios.post('profile/create_facture', {
        codeFacture:codeFacture,
        IsEmailClientSent:pe_IsEmailFacture,
      }
    ).then(({data}) => {
    //  console.log("userUpdated: ", data);

      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }

};

export const generateAvoir = (codeAvoir, pe_IsEmailAvoir) => {

  return (dispatch) => {

    dispatch({type: FETCH_START});

    axios.post('profile/create_avoir', {
        codeAvoir:codeAvoir,
        IsEmailClientSent:pe_IsEmailAvoir,
      }
    ).then(({data}) => {
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateFacture = (facture) => {
  return (dispatch) => {


    dispatch({type: FETCH_START});

    axios.post('profile/facture', {
        Facture: facture,
      }
    ).then(({data}) => {
    //  console.log("userUpdated: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      //  window.location.replace("/moncompte")
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateAvoir = (avoir) => {
  return (dispatch) => {


    dispatch({type: FETCH_START});

    axios.post('profile/avoir', {
        Avoir: avoir,
      }
    ).then(({data}) => {
    //  console.log("userUpdated: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      //  window.location.replace("/moncompte")
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateIntervention = (intervention) => {
  return (dispatch) => {


    dispatch({type: FETCH_START});

    axios.post('profile/intervention', {
        Intervention: intervention,
      }
    ).then(({data}) => {
    //  console.log("userUpdated: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      //  window.location.replace("/moncompte")
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

//*****************************************************************
//-------------- ------ --  INTERVENTION PART  -------------- ------ --
//*****************************************************************

export const getIntervention = (offset,quantity,pe_IDStatutIntervention) => {



  return (dispatch) => {
    dispatch({type: FETCH_START});
    var IDStatutIntervention = 0;
/*
    dispatch({
      type: USER_INTERVENTION_DATA_CLEAN,
      payload: [],
    });*/


    if(pe_IDStatutIntervention === undefined){
      IDStatutIntervention = 0;
    }else{
      IDStatutIntervention = pe_IDStatutIntervention
      if(offset === 0){
        dispatch({
          type: USER_INTERVENTION_DATA_CLEAN,
          payload: [],
          offset:offset + quantity
        });
      }
    }

    axios.get('profile/'+offset+"/"+quantity + '/' + IDStatutIntervention + '/intervention',
    ).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_INTERVENTION_DATA,
          payload: data.intervention_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const updateEtatIntervention = (IDStatutIntervention,CodeIntervention) => {
  return (dispatch) => {


    dispatch({type: FETCH_START});

    axios.post('profile/updateStatutIntervention', {
        IDStatutIntervention:IDStatutIntervention,
        CodeIntervention:CodeIntervention,
      }
    ).then(({data}) => {
    //  console.log("userUpdated: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS , payload: data.success});
      //  window.location.replace("/moncompte")
      } else {
        console.log(data.error)
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

//*****************************************************************
//-------------- ------ --  MODELE PART  -------------- ------ --
//*****************************************************************

export const getModele = (pe_IDMarque) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});


    dispatch({
      type: USER_MODELE_DATA_CLEAN,
      payload: [],
    });


    var IDMarque = 0;
    if(pe_IDMarque === undefined){
      IDMarque = 0;
    }else{
      IDMarque = pe_IDMarque

    }

    axios.get('profile/' + IDMarque + '/modele',
    ).then(({data}) => {

      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_MODELE_DATA,
          payload: data.modele_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

//*****************************************************************
//-------------- ------ --  BLPART  -------------- ------ --
//*****************************************************************

export const getDashboard = () => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    axios.get('profile/dashboard/')
    .then(({data}) => {


      //console.log(data)
      if (data.success) {
        console.log(data);
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_DASHBOARD_DATA,
          payload: data.dashboard_LIST
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


//*****************************************************************
//-------------- ------ -- B2B REPARATION PART  -------------- ------ --
//*****************************************************************

export const getReparation = (offset,quantity,pe_IDStatutIntervention) => {



  return (dispatch) => {
    dispatch({type: FETCH_START});
    var IDStatutIntervention = 0;

    dispatch({
      type: USER_INTERVENTION_DATA_CLEAN,
      payload: [],
    });

    if(pe_IDStatutIntervention === undefined){
      IDStatutIntervention = 0;
    }else{
      IDStatutIntervention = pe_IDStatutIntervention
      if(offset === 0){
        dispatch({
          type: USER_INTERVENTION_DATA_CLEAN,
          payload: [],
          offset:offset + quantity
        });
      }
    }

    axios.get('factureB2B/'+offset+"/"+quantity + '/' + IDStatutIntervention + '/reparation',
    ).then(({data}) => {
      console.log("userReparation: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_INTERVENTION_DATA,
          payload: data.intervention_LIST,
          offset:offset + quantity
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getPanierAchat = () => {



  return (dispatch) => {
    dispatch({type: FETCH_START});


    dispatch({
      type: USER_PANIER_ACHAT_DATA_CLEAN,
      payload: [],
    });


    axios.get('profile/panierAchat',
    ).then(({data}) => {

      if (data.success) {
      //  console.log('profile/panierAchat')
      //  console.log(data.panier_LIST)

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_PANIER_ACHAT_DATA,
          payload: data.panier_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getTelephone = () => {



  return (dispatch) => {
    dispatch({type: FETCH_START});
/*
    dispatch({
      type: USER_TELEPHONE_LIST_DATA_CLEAN,
      payload: [],
    });
*/
    axios.get('profile/telephoneB2B',
    ).then(({data}) => {
      console.log("telephoneB2B: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_TELEPHONE_LIST_DATA,
          payload: data.article_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


//*****************************************************************
//-------------- ------ -- B2B FACTURE PART  -------------- ------ --
//*****************************************************************

export const getFactureClient = (pe_IDEtatFacture, pe_IDTypeFacture) => {



  var IDEtatFacture = 0;
  var IDTypeFacture = 0;

  return (dispatch) => {

      if(pe_IDEtatFacture === undefined){
        IDEtatFacture = 0;
      }else{
        IDEtatFacture = pe_IDEtatFacture
      }

      if(pe_IDTypeFacture === undefined){
        IDTypeFacture = 0;
      }else{
        IDTypeFacture = pe_IDTypeFacture
      }

      dispatch({
        type: USER_FACTURE_DATA_CLEAN,
        payload: [],
      });

    dispatch({type: FETCH_START});

    axios.get('/factureB2B/'+ IDEtatFacture +'/'+ IDTypeFacture + '/loadFactureClient',
    ).then(({data}) => {
      console.log("userFacture: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_FACTURE_DATA,
          payload: data.facture_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const getRemise = () => {
  console.log('getRemise !!!!!!!!!!!!!!!!!!!!!!!!!!' );
  return (dispatch) => {
      dispatch({
        type: USER_REMISE_DATA_CLEAN,
        payload: [],
      });

    dispatch({type: FETCH_START});

    axios.get('profile/remise',
    ).then(({data}) => {
      console.log("userFacture: ", data);
      if (data.success) {
        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_REMISE_DATA,
          payload: data.remise_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

/////////////////: GESTON FLOTTE ///////////////
export const getFlotte = (IDClient, pe_ISpreactivee, pe_search) => {

  console.log('getFlotte !!!!!!!!!!!!!!!!!!!!!!!!!! ->  ' + IDClient + 'pe_search->' + pe_search);

  return (dispatch) => {

      dispatch({
        type: USER_FLOTTE_DATA_CLEAN,
        payload: [],
      });

    dispatch({type: FETCH_START});

    let vl_req = '';

    if(pe_search !== ''){
      vl_req = `profile/${IDClient}/${pe_ISpreactivee}/${pe_search}/flotte`;
    }else{
      vl_req = `profile/${IDClient}/${pe_ISpreactivee}/flotte`;
    }
    axios.get(vl_req,
    ).then(({data}) => {
      //console.log("userFlotte: ", data);
      if (data.success) {

        //console.log(data.flotte_LIST);

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_FLOTTE_DATA,
          payload: data.flotte_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const flotteB2B = (pe_IDClientSelected, pe_Ispreactivee, pe_search) => {

  return (dispatch) => {

      dispatch({
        type: USER_FLOTTE_DATA_CLEAN,
        payload: [],
      });

    dispatch({type: FETCH_START});

    let url = ``;

    if(pe_search != '') {
      url =   `flotteB2B/${pe_IDClientSelected}/${pe_Ispreactivee}/${pe_search}/flotteB2B`
    }else{
      url =   `flotteB2B/${pe_IDClientSelected}/${pe_Ispreactivee}/flotteB2B`
    }

    axios.get(url,
    ).then(({data}) => {
    //  console.log("userFlotte: ", data);
      if (data.success) {

      //  console.log(data.flotte_LIST);

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_FLOTTE_DATA,
          payload: data.flotte_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


/////////////////: GESTON FLOTTE ///////////////
export const getSim = (IDClient) => {

  console.log('getSim !!!!!!!!!!!!!!!!!!!!!!!!!! ->  ' + IDClient );

  return (dispatch) => {

      dispatch({
        type: USER_SIM_DATA_CLEAN,
        payload: [],
      });

    dispatch({type: FETCH_START});

    axios.get('profile/'+ IDClient + '/sim',
    ).then(({data}) => {
      console.log("Sim: ", data);
      if (data.success) {

        console.log(data.sim_LIST);

        dispatch({type: FETCH_SUCCESS});
        dispatch({
          type: USER_SIM_DATA,
          payload: data.sim_LIST,
        });
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};


export const updateCartItem = () => {

  return (dispatch) => {

    axios.get('cart/totalItem',
    ).then(({data}) => {

      if (data.success) {

        if(data.panier_LIST != undefined) {
            if(data.panier_LIST.length > 0){
              dispatch({type: USER_ITEM_CART , totalCartItem: data.panier_LIST[0].Quantite});
            }else{
              dispatch({type: USER_ITEM_CART , totalCartItem: 0});
            }

        }else{
            dispatch({type: USER_ITEM_CART , totalCartItem: 0});
        }

      } else {
        dispatch({type: FETCH_ERROR, payload: data.error});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const generateParamLists = () => {
  return (dispatch) => {
    dispatch(generateListSites());
    dispatch(generateListSitesDashboard());
    dispatch(generateListEtatsIntervention());
    dispatch(generateListPieces());
    dispatch(generateListMarques());
    dispatch(generateListEtatsComposants());
    dispatch(generateListFournisseurs());
    dispatch(generateListConseillers());
    dispatch(generateListTypesDocument());
    dispatch(generateListDelaisPaiment());
    dispatch(generateListGroupements());
    dispatch(generateListModesReglement());
    dispatch(generateListTypesCharge());
    dispatch(generateListAllModeles());
    dispatch(generateListEtatsNavette());
    dispatch(generateListEtatsReconditionnement());
    dispatch(generateListEtatsDevis());
    dispatch(generateListEtatsFacture());
    dispatch(generateListCodePromo());
    dispatch(generateListPays());
    dispatch(generateListComptes());
    dispatch(generateListEtatsPrelevement());
    dispatch(generateListTypeContactClient());
    dispatch(generateListEtatsRemise());
    dispatch(generateListTauxTVA());
    dispatch(generateListModesReglementReprise());
    dispatch(generateListEtatsDemantelement());
    dispatch(generateListEtatsWholesale());
    dispatch(generateListEtatsInventaire());
    dispatch(generateListEtatComposantInventaire());
    dispatch(generateListSociete());
    dispatch(generateListConditionnement());
    dispatch(generateListEtatsOpportunite());
    dispatch(generateListTypeRefusDevis());
    dispatch(generateListSitesBoutique());
    dispatch(generateListEtatRepriseClient());
    dispatch(generateListImprimante());
    dispatch(generateListOperateurTelecomDashboard());

  }
}

const generateList = (url, listType, IDItem, libelleItem, optionnalLibelle=null) => {
  return (dispatch) => {
    axios.get(url,
    ).then(({data}) => {
      if(data.success) {

        dispatch({type: USER_GENERATE_PARAM_LIST, payload: data[listType], listType});
        var listToOptions = [];
        for(let i = 0; i < data[listType].length; i++) {
          let value = data[listType][i][IDItem];
          let libelle = data[listType][i][libelleItem];
          if(optionnalLibelle !== null)
            libelle = data[listType][i][libelleItem] + ' ' +data[listType][i][optionnalLibelle];
          listToOptions.push(<Option value={value} key={value}>{libelle}</Option>);
        }
        dispatch({type: USER_GENERATE_PARAM_LIST, payload: listToOptions, listType: listType+'_options'});
      } else
        dispatch({type: FETCH_ERROR, payload: data.error});
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
}

const generateListSites = () => {
  return (dispatch) => {
    dispatch(generateList('site/site', 'site_LIST', 'IDSite', 'LibelleSite'));
  }
}

const generateListSitesBoutique = () => {
  return (dispatch) => {
    dispatch(generateList('site/siteBoutique', 'siteBoutique_LIST', 'IDSite', 'LibelleSite'));
  }
}

const generateListSitesDashboard = () => {
  return (dispatch) => {

    dispatch(generateList('site/siteDashboard', 'siteDashboard_LIST', 'IDSite', 'LibelleSite'));
  }
}



const generateListOperateurTelecomDashboard = () => {
  return (dispatch) => {

    dispatch(generateList('operateurTelecom/operateurTelecom', 'operateurMobile_LIST', 'IDOperateurMobile', 'LibelleOperateurMobile'));
  }
}





const generateListSociete = () => {
  return (dispatch) => {
    dispatch(generateList('societe/societe', 'societe_LIST', 'IDSociete', 'LibelleSociete'));
  }
}


const generateListTauxTVA = () => {
  return (dispatch) => {
    dispatch(generateList('facture/tauxTVA', 'tauxTVA_LIST', 'IDTauxTVA', 'TauxTVA'));
  }
}


const generateListEtatRepriseClient = () => {
  return (dispatch) => {
    dispatch(generateList('repriseClient/etatRepriseClient', 'etatRepriseClient_LIST', 'IDEtatRepriseClient', 'LibelleEtatRepriseClient'));
  }
}



const generateListImprimante = () => {
  return (dispatch) => {
    dispatch(generateList('imprimante/imprimante', 'imprimante_LIST', 'IDImprimante', 'LibelleImprimante'));
  }
}





const generateListEtatsRemise = () => {
  return (dispatch) => {
    dispatch(generateList('/remise/etatRemise', 'etatRemise_LIST', 'IDEtatRemise', 'LibelleEtatRemise'));
  }
}


const generateListEtatsDemantelement = () => {
  return (dispatch) => {
    dispatch(generateList('/demantelement/etatDemantelement', 'etatDemantelement_LIST', 'IDEtatDemantelement', 'LibelleEtatDemantelement'));
  }
}



const generateListTypeRefusDevis = () => {
  return (dispatch) => {
    dispatch(generateList('/devis/typeRefusDevis', 'typeRefusDevis_LIST', 'IDTypeRefusDevis', 'LibelleTypeRefusDevis'));
  }
}






const generateListEtatsIntervention = () => {
  return (dispatch) => {
    dispatch(generateList('/intervention/etatIntervention', 'etatIntervention_LIST', 'IDEtatIntervention', 'LibelleEtatIntervention'));
  }
}

const generateListEtatsOpportunite = () => {
  return (dispatch) => {
    dispatch(generateList('/intervention/etatOpportunite', 'etatOpportunite_LIST', 'IDEtatIntervention', 'LibelleEtatIntervention'));
  }
}


const generateListEtatsInventaire = () => {
  return (dispatch) => {
    dispatch(generateList('/inventaire/etatInventaire', 'etatInventaire_LIST', 'IDEtatInventaire', 'LibelleEtatInventaire'));
  }
}

const generateListEtatsNavette = () => {
  return (dispatch) => {
    dispatch(generateList('/navette/etatNavette', 'etatNavette_LIST', 'IDEtatNavette', 'LibelleEtatNavette'));
  }
}

const generateListPieces = () => {
  return (dispatch) => {
    dispatch(generateList('profile/loadPiece', 'piece_LIST', 'IDPiece', 'LibellePiece'));
  }
}

const generateListMarques = () => {
  return (dispatch) => {
    dispatch(generateList('profile/marque', 'marque_LIST', 'IDMarque', 'LibelleMarque'));
  }
}

const generateListEtatsComposants = () => {
  return (dispatch) => {
    dispatch(generateList('composant/etatComposant', 'etatComposant_LIST', 'IDEtatComposant', 'LibelleEtatComposant'));
  }
}

const generateListFournisseurs = () => {
  return (dispatch) => {
    dispatch(generateList('/profile/fournisseur', 'fournisseur_LIST', 'IDFournisseur', 'LibelleFournisseur'));
  }
}

const generateListConseillers = () => {
  return (dispatch) => {
    dispatch(generateList('client/conseiller', 'conseiller_LIST', 'IDConseiller', 'ConseillerCommerciaLibelle'));
  }
}


const generateListTypeContactClient = () => {
  return (dispatch) => {
    dispatch(generateList('client/typeContactClient', 'typeContact_LIST', 'IDTypeContactClient', 'LibelleTypeContactClient'));
  }
}



const generateListComptes = () => {
  return (dispatch) => {
    dispatch(generateList('client/compte', 'employe_LIST', 'IDEmploye', 'Employe'));
  }
}



const generateListTypesDocument = () => {
  return (dispatch) => {
    dispatch(generateList('/document/typeDocument', 'typeDoc_LIST', 'IDTypeDocument', 'LibelleTypeDocument'));
  }
}

const generateListDelaisPaiment = () => {
  return (dispatch) => {
    dispatch(generateList('/client/delaisPaiement', 'delaisPaiement_LIST', 'IDEtatDelaisPaiement', 'LibelleEtatDelaisPaiement'));
  }
}

const generateListGroupements = () => {
  return (dispatch) => {
    dispatch(generateList('client/groupement', 'groupement_LIST', 'IDClient', 'Societe'));
  }
}

const generateListModesReglement = () => {
  return (dispatch) => {
    dispatch(generateList('/facture/mode_reglement', 'modeReglement_LIST', 'IDModeReglement', 'LibelleModeReglement'));
  }
}


const generateListModesReglementReprise = () => {
  return (dispatch) => {
    dispatch(generateList('/facture/mode_reglementReprise', 'modeReglementReprise_LIST', 'IDModeReglement', 'LibelleModeReglement'));
  }
}

const generateListTypesCharge = () => {
  return (dispatch) => {
    dispatch(generateList('/chargefixe/typechargeFixe', 'typeCharge_LIST', 'IDTypeChargeFixe', 'LibelleTypeChargeFixe'));
  }
}

const generateListAllModeles = () => {
  return (dispatch) => {
    dispatch(generateList('profile/0/modele', 'modele_LIST', 'IDModele', 'LibelleMarque', 'LibelleModele'));
  }
}


const generateListEtatsReconditionnement = () => {
  return (dispatch) => {
    dispatch(generateList('/reconditionnement/etatReconditionnement', 'etatReconditionnement_LIST', 'IDEtatReconditionnement', 'LibelleEtatReconditionnement'));
  }
}

const generateListEtatsWholesale = () => {
  return (dispatch) => {
    dispatch(generateList('/wholesale/etatWholesale', 'etatWholesale_LIST', 'IDEtatWholesale', 'LibelleEtatWholesale'));
  }
}

const generateListEtatsDevis = () => {
  return (dispatch) => {
    dispatch(generateList('/devis/etatDevis', 'etatDevis_LIST', 'IDEtatDevis', 'LibelleEtatDevis'));
  }
}

const generateListEtatsFacture = () => {
  return (dispatch) => {
    dispatch(generateList('/facture/etatFacture', 'etatFacture_LIST', 'IDEtatFacture', 'LibelleEtatFacture'));
  }
}

const generateListEtatsPrelevement = () => {
  return (dispatch) => {
    dispatch(generateList('/facture/etatPrelevement', 'etatPrelevement_LIST', 'IDEtatPrelevement', 'LibellePrelevement'));
  }
}

const generateListCodePromo = () => {
  return (dispatch) => {
    dispatch(generateList('/intervention/codePromo', 'codePromo_LIST', 'IDCodePromo', 'CodePromo'));
  }
}

const generateListPays = () => {
  return (dispatch) => {
    dispatch(generateList('/client/pays', 'pays_LIST', 'IDPays', 'LibellePays'));
  }
}


const generateListEtatComposantInventaire = () => {
  return (dispatch) => {
    dispatch(generateList('/inventaire/etatComposantInventaire', 'etatComposantInventaire_LIST', 'IDEtatComposant', 'LibelleEtatComposant'));
  }
}


const generateListConditionnement = () => {
  return (dispatch) => {
    dispatch(generateList('/reco/conditionnement', 'cond_LIST', 'IDTarifClient', 'BoiteDesignation'));
  }
}



const saveItem = (url, item, refreshFunction) => {
  return (dispatch) => {
    axios.post(url, item
    ).then(({data}) => {
      message.success('Modification réalisée avec succès');
      dispatch(refreshFunction);
    }).catch(function (error) {
      console.log("Error****:", error.message);
      message.error('Erreur lors de la modification');
    });
  }
}

export const saveMarque = (Marque) => {
  return (dispatch) => {
    dispatch(saveItem('article/marque', {Marque}, generateListMarques()));
  }
}

export const saveFournisseur = (Fournisseur) => {
  return (dispatch) => {
    dispatch(saveItem('fournisseur/fournisseur', {Fournisseur}, generateListFournisseurs()));
  }
}

export const savePiece = (Piece) => {
  return (dispatch) => {
    dispatch(saveItem('piece/updatePiece', {Piece}, generateListPieces()));
  }
}
